<template>
	<div>
		<div class="row">
			<div class="col" v-if="pesquisa.resultado.length > 0">
				<div class="cards">
					<modulo v-for="(modulo, index) in pesquisa.resultado" :key="index" :modulo="modulo" @abrir="abrirModulo($event)"/>
				</div>
			</div>
			<div class="col-12 my-5 text-center" v-else>Você não tem cursos adquiridos</div>
		</div>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import modulo from '@/components/admin/meuscursos/Modulo.vue'


export default {
	name: 'MeusCursos',
	data: function() {
		return {
         pesquisa: {'nome': '', 'resultado': []},
         modulo: {'id': null, 'nome': null, 'descricao': null, 'codigo': null, 'nomeArquivo': null},
		}
	},
	components: {
      modulo
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
		searchMeusModulos : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/searchMeusModulos',
            params: {
               id: this.dadosUsuario.id
            }
         }).then(response => {
				ref.pesquisa.resultado = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirModulo(modulo) {
         this.$router.push('/admClassroom/' + modulo.id);
      },
	},
	mounted() {
		this.searchMeusModulos()
	}
}

</script>